import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  name: 'ImpactLabel',
  props: {
    link: String,
    index: Number,
    size: Number,
    active: Boolean,
    isPositive: Boolean
  },
  computed: {
    isActive: function isActive() {
      if (this.active !== null) {
        return this.active;
      } else {
        return false;
      }
    }
  }
});